import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import axiosInstance from "../../axiosInstance";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "../Model";
// import ImageUploader from "../components/ImageUploader";
import ImageUploader from "../ImageUploader";
import { TextField } from "@mui/material";

const ImagePicker = (props) => {
  const [file, setFile] = React.useState(props.image_url);
  const inputFile = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);
  React.useEffect(() => {
    setFile(props.image_url);
    // console.log('props.image_url', props.image_url);
  }, [props.image_url]);

  const handleChange = async (e) => {
    setIsLoading(true);
    try {
      let formData = new FormData(); // instantiate it
      // suppose you have your file ready
      formData.set("file", e.target.files[0]);
      formData.set("siteId", props.siteId);

      // formData.set('data1', dataInputField1); // suppose you got dataInputField1 from your HTML5 form input
      // console.log("axiosInstance", axiosInstance);
      // const axiosI = new axios.create({
      //   baseURL: process.env.API_ENDPOINT,
      // });
      let res = await axios.post(
        `${process.env.API_ENDPOINT === ""
          ? "http://localhost:3000"
          : process.env.API_ENDPOINT
        }/${process.env.IMAGE_UPLOAD_PATH}`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
          maxRedirects: 200,
        }
      );
      setFile(res.data.url);
      setIsLoading(false);
      props.onImageChange(res.data.url);
      e.target.value = "";
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col text-black">
       <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <ImageUploader
          siteId={props.siteId}
          onClose={() => setModalOpen(false)}
          onChange={url => {
            setFile(url);
            props.onImageChange(url);
            setModalOpen(false)
          }}
          // eid={getQueryParam("eid")}
          // thumbnailsize={getQueryParam("thumbnailsize")}
        />
      </Modal>
      {props.title && <span className="text-base-2 mb-2">{props.title}</span>}
      {props.desc && <span className="font-normal mb-2">{props.desc}</span>}
      <div
        className={ `w-full border border-catelina-blue/60 rounded flex flex-col   
          ${
          props.size === "small"
            ? "h-40 md:w-48"
            : props.size === "full"
            ? "w-full"
            : "h-60 md:w-80" 
        }
         ${  props.error ? 'border-red-400' : 'border-catelina-blue/60'  } 
        `}>
        <div className="border-b border-catelina-blue/60  h-10 flex flex-row justify-end items-center px-2 ">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <input
            className="hidden"
            type="file"
            onChange={handleChange}
            ref={inputFile}
          />
        {/*  <EditNoteOutlinedIcon
            className="text-catelina-blue mr-2 hover:cursor-pointer"
            fontSize="small"
              onClick={() => {
              setModalOpen(true);
              // inputFile.current.click()
            }}
          />
           <DeleteOutlineOutlinedIcon
            className="text-catelina-blue hover:cursor-pointer"
            fontSize="small"
            onClick={() => {
              setFile("");
              props.onImageChange && props.onImageChange("");
            }}
          /> */}

        <img className='text-catelina-blue mr-2 hover:cursor-pointer ml-2' width="18" src={require("../../images/edit.svg")} 
           onClick={() => {
            setModalOpen(true); 
          }} /> 


          <img className='text-catelina-blue mr-2 hover:cursor-pointer ml-2' width="18" src={require("../../images/delete.svg")} 
           onClick={() => {
            setFile(null);
            props.onImageChange && props.onImageChange('')
          }} /> 

        </div>
        <div className={"h-full flex justify-center items-center px-4 mx-2 mb-1 border-1  border-catelina-blue/60" + (props.showAlt ? ' border-l border-b border-r bg-[#FAFAFA]' : '')}>
          {file !== "" && <img className="h-52 object-contain" src={file} />}
          {(file === "" || file === undefined || !file) && (
            // <ImageNotSupportedOutlinedIcon className="" fontSize="large" />
            <img className='text-catelina-blue mr-2 hover:cursor-pointer ml-2' width="40" src={require("../../images/no-image.svg")} />
          )}
        </div>
        {props.showAlt && <div className="flex  justify-around items-center mb-4">
            <span className="font-light">Alt & Title  <InfoOutlinedIcon width="20" sx={{color: '#243060'}} /></span>
            <TextField
            className="border rounded border-red py-1 text-base-2 w-3/5 "
            placeholder="Image Name" 
            size="small"
            defaultValue={props.altValue}
            onChange={(e) => props.onAltChange(e.target.value)}
            error={props.altError}
            InputProps={{ 
              classes: {
                input: 'text-base', // Add a class to the input element 
              },
              sx: { 
                fontSize: '16px',
                '& .MuiOutlinedInput-notchedOutline' : {
                  borderColor: '#24306099',
                  borderWidth: 1,
                  width: '100%'
                },
                '& .MuiFormControl-root' : {
                  width: '250px'
                }
              }
            }}
          />
          </div>}
      </div>
      {props.notes && (
        <div className="items-center text-xsm">
          <InfoOutlinedIcon fontSize="inherit" />

          <span className="ml-1">{props.notes}</span>
        </div>
      )}
    </div>
  );
};

export default ImagePicker;
