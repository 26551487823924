import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../../index.css";
import axiosInstance from "../../axiosInstance";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
// import Modal from "@mui/material/Modal";
import AddEditColorPallets from "./AddEditColorPallets";

const ColorPalletsList = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [currFormData, setCurrFormData] = React.useState(null);

  async function fetchData() {
    try {
      let response = await axiosInstance.get(
        `/api/configs/all-template-color-theme`
      );

      // console.log("//", response.data.data);
      setData(response.data.data);
    } catch (err) {}
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };
  const handleSave = () => {
    fetchData();
    setOpen(false);
  };

  const handleDelete = async (id) => {
    let res = await axiosInstance.delete(
      `/api/configs/template-color-theme?id=${id}`,
      id
    );
    if (res.status === 200) {
      fetchData();  
    } else {
      enqueueSnackbar("something went wrong...!", {
        variant: "error",
      });
    }
    
  };
  return (
    <div className="p-4 bg-white  w-auto max-w-xl">
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-gray-900 opacity-50"
            onClick={() => setOpen(false)}
          ></div>
          <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 z-50">
            <AddEditColorPallets
              data={data}
              currFormData={currFormData}
              onCancel={handleCancel}
              onSave={handleSave}
            />
          </div>
        </div>
      )}

      {/* <AddEditColorPallets /> */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modelStyle}>
          <AddEditColorPallets />
        </Box>
      </Modal> */}
      <div className="text-black flex flex-row justify-between mb-4">
        <span>List of color pallets</span>
        <Button
          sx={{
            textTransform: "none",
          }}
          size="small"
          variant="contained"
          onClick={() => {
            setCurrFormData(null);
            setOpen(true);
          }}
        >
          Add new color pallet
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow className="p-2 ">
              <TableCell>id</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Colors</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.category}
                </TableCell>
                <TableCell align="right">
                  <div className="flex flex-row">
                    {row.colors.map((ii) => {
                      return (
                        <div
                          key={ii}
                          className={`h-8 w-8 rounded-md mr-1 border border-grey`}
                          style={{ backgroundColor: ii }}
                        >
                          {" "}
                        </div>
                      );
                    })}{" "}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      sx={{
                        textTransform: "none",
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        setCurrFormData(row);
                        setOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      sx={{
                        textTransform: "none",
                      }}
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        handleDelete(row.id)
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ColorPalletsList;
