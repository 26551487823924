import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImagePicker from '../SiteSettings/ImagePicker';

export default function BlogDetailsForm(props) {
  const [blogDetails, setBlogDetails] = useState<any>(props?.blogConfig?.blog ?? { url: 'blog' });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    // if (!props?.blogConfig?.blog?.url) return;

    const url = props?.blogConfig?.blog?.url?.startsWith('/') ? props?.blogConfig?.blog?.url?.substr(1) : props?.blogConfig?.blog?.url;
    setBlogDetails({ ...props?.blogConfig?.blog, url: url || 'blog' });
  }, [JSON.stringify(props?.blogConfig?.blog ?? {})]);

  const validateForm = () => {
    const errors = {};
    if (!blogDetails.title) {
      errors['title'] = 'Title is required';
    }
    if (!blogDetails.desc) {
      errors['desc'] = 'Description is required';
    }
    if (!blogDetails.url) {
      errors['url'] = 'Blog URL is required';
    } else {
      if (props.sitePages?.map(page => page?.pageUrl?.split('/')?.filter(urlSection => urlSection !== '/' && !!urlSection)[0]).find(url => url === blogDetails.url)) {
        errors['url'] = 'Page already exists with this URL';
      }
    }

    if (!blogDetails.metaImage) {
      errors['metaImage'] = 'Meta Image is required';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = () => {
    if (!validateForm()) return;

    props.onBlogConfigChange({ ...props.blogConfig, blog: { ...props.blogConfig.blog, ...blogDetails }});
  };

  return (
    <>
      <div className='flex gap-10 flex-col-reverse lg:flex-row'>
        <div className='image-upload-box'>
          <ImagePicker
            size='full'
            image_url={blogDetails?.metaImage}
            siteId={props.siteId}
            title="Bloglist Meta Image"
            notes="Please upload a .PNG or .JPG image."
            onImageChange={img_url => setBlogDetails({ ...blogDetails, metaImage: img_url })}
            error={!!errors['metaImage']}
            altError={!!errors['metaImageAlt']}
            showAlt
            altValue={blogDetails?.metaImageAlt}
            onAltChange={value => setBlogDetails({ ...blogDetails, metaImageAlt: value })}
          />
        </div>
        <div className='w-full'>
        <label className='text-base-2 mb-2  text-grey-primary'>Blog Title</label>
        <TextField
          value={blogDetails?.title}
          className="w-full border rounded border-red py-1 text-base-2 mb-3  bg-white"
          placeholder="Blog Title"
          onChange={e => setBlogDetails({ ...blogDetails, title: e.target.value })}
          size="small"
          error={!!errors['title']}
          helperText={errors['title']}
          InputProps={{
            classes: {
              input: 'text-base', // Add a class to the input element
            },
            sx: {
              fontSize: '16px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#24306099',
                borderWidth: 1
              }
            }
          }}
        />

        <label className='text-base-2 mb-2  text-grey-primary'>Blog Description</label>
        <TextField
          value={blogDetails?.desc}
          className="w-full border rounded border-red py-1 text-base-2 mb-3  bg-white"
          placeholder="Blog Description"
          onChange={e => setBlogDetails({ ...blogDetails, desc: e.target.value })}
          size="small"
          error={!!errors['desc']}
          helperText={errors['desc']}
          InputProps={{
            classes: {
              input: 'text-base', // Add a class to the input element
            },
            sx: {
              fontSize: '16px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#24306099',
                borderWidth: 1
              }
            }
          }}
        />

        <label className='md:text-base-2 text-md mb-2 text-grey-primary flex'>Blog URL</label>
        <TextField
          value={blogDetails?.url}
          // value={blogUrl ?? ''}
          className="w-full border rounded border-catelina-blue/60  text-base  mr-4 "
          onChange={e => {
            let value = e.target.value;
            if (value.startsWith('/')) {
              value = value.substring(1);
            }
            if (value.endsWith('/')) {
              value = value.substring(0, value.length - 1);
            }

            setBlogDetails({ ...blogDetails, url: value });
          }}
          // size="small"
          error={!!errors['url']}
          helperText={errors['url'] ?? ''}
          InputProps={{
            startAdornment: 'xyz.rocketpages.io/',
            sx: {
              fontSize: '16px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#24306099',
                borderWidth: 1
              }

            },
            classes: {
              input: 'py-2 text-base', // Add a class to the input element
            },

          }}
          sx={{
            padding: '0',
          }}
        />
        </div>
        
      </div>
      <div className='flex justify-end'>
        <Button
          id="save"
          className="py-1 md:w-20 w-full md:mt-0 mt-2  rounded-md"
          sx={{
            textTransform: "none",
          }}
          variant="contained"
          disabled={JSON.stringify(blogDetails) === JSON.stringify(props?.blogConfig?.blog)}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </>
  );
}

