import Quill from 'quill';
import "quill/dist/quill.snow.css";
import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import ImageUploader from '../ImageUploader';

type EditorProps = {
  readOnly?: boolean;
  siteId?: string;
  defaultValue?: any;
  content?: string;
  onTextChange?: (...args: any[]) => void;
  onSelectionChange?: (...args: any[]) => void;
  error?: boolean;
};

// Editor is an uncontrolled React component
const Editor = forwardRef<Quill, EditorProps>(
  ({ readOnly, siteId, defaultValue, content, onTextChange, onSelectionChange, error }, ref: any) => {
    const containerRef = useRef(null);
    // const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const [showImageUpload, setShowImageUpload] = React.useState(false);
    const [range, setRange] = React.useState<any>(null);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      if (ref?.current?.root) {
        ref.current.root.innerHTML = content;
      }
    }, [content]);

    useEffect(() => {
      const container: any = containerRef.current;

      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = new Quill(editorContainer, {
        modules: {
          toolbar: {
            container: [
              [
                // { 'font': [] }, 
                { 'size': [] }
              ],
              [ 'bold', 'italic', 'underline', 'strike' ],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'script': 'super' }, { 'script': 'sub' }],
              [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
              [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
              [ 'direction', { 'align': [] }],
              [ 'link', 'image', 'video', 'formula' ],
              [ 'clean' ]
            ],
            handlers: {
              'image': () => {
                // setShowImageUpload(true);
                const range = ref.current.getSelection();
                // setRange(range);
                insertImage('https://pagebuilder-images-bucket.s3.amazonaws.com/01c1d933-53c9-4b12-803a-b264d5e94cd7-ScreenshotatPM.png');
              },
            }
          },
        },
        placeholder: 'Enter post content here...',
        theme: 'snow',
      });

      ref.current = quill;

      if (content) {
        // quill.setContents(defaultValue);

        quill.root.innerHTML = content;
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(quill.root.innerHTML);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

  function insertImage(url) {
    console.log('insertImage', url);
    // const range = ref.current.getSelection();
    ref.current.insertEmbed(range?.index, 'image', url);
    setShowImageUpload(false);
  }

    return <>
      <div className={error ? 'border border-red-300': 'py-1'} ref={containerRef}></div>
      {showImageUpload && <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 100,
        backgroundColor: 'white',
        borderRadius: '10px',
        width: '80%',
        height: '80%',
      }}>
        <ImageUploader siteId={siteId} onClose={() => setShowImageUpload(false)} onSelect={url => insertImage(url)} />
      </div>}
      {!!error && <div className="text-red-500 text-sm">{error}</div>}
    </>;
  }
);

Editor.displayName = 'Editor';

export default Editor;