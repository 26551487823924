// import "quill/dist/quill.snow.css";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Button, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import Quill from 'quill';
import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import ImagePicker from '../SiteSettings/ImagePicker';
import Editor from '../shared/Editor';

// const Delta = Quill.import('delta');



export default function BlogCreate(props) {
  const quillRef = useRef<Quill>(null);
  const [postData, setPostData] = useState(props?.post ?? {}); // form data
  const [post, setPost] = useState<any>({}); // post we get from backend (full data in here)
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();  
  useEffect(() => {
    if (!props.post) return;

    (async () => {
      const post = await axiosInstance.get('/api/configs/post-for-admin', {params: {id: props.post?.id}});
      console.log('post.data', post.data);
      setPost(post.data.post);
      setPostData(post.data.post);
    })();
  }, []);

  const validateForm = (action = 'save') => {
    const errors = {};
    if (!postData.title) {
      errors['title'] = 'Title is required';
    }

    let isSlugAlreadyUsed = !!props.posts.find(p => (p.slug === postData.slug && p.id !== postData.id)); // find if slug is already used
    if (isSlugAlreadyUsed) {
      errors['slug'] = 'Slug is already used';
    }

    if (!postData.slug) {
      errors['slug'] = 'Slug is required';
    }

    if (action === 'publish') {
      if (!postData.bannerImage) {
        errors['bannerImage'] = 'Banner image is required';
      }
      if (!postData.bannerImageAlt) {
        errors['bannerImageAlt'] = 'Banner image alt text is required';
      }
      if (!quillRef.current?.root.innerText) {
        errors['content'] = 'Content is required';
      } else if (quillRef.current?.root.innerText.length < 50) {
        errors['content'] = 'Content should be atleast 50 characters';
      }
    }

    console.log('errors', errors, {...postData, innerText: quillRef.current?.root?.innerText});
    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      // scroll to top smoothly
      window.scrollTo({top: 0, behavior: 'smooth'});
      enqueueSnackbar('Please fix errors!', { variant: 'error' });
    }

    return Object.keys(errors).length === 0;
  };

  const handleBlogCreate = async () => {
    setIsLoading(true);
    const innerText = quillRef?.current?.root.innerHTML;
    
    setPostData({...postData, content: innerText});

    if (!validateForm(!post?.publishedAt ? 'save' : 'publish')) {
      setIsLoading(false);
      return;
    }

    const assets = getImageUrlsFromContent(innerText);

    const res = await axiosInstance.post('/api/configs/post-for-admin', {
      ...post,
      ...postData,
      siteId: props.siteId,
      content: innerText,
      assets,
    });

    if (res.data.error) {
      enqueueSnackbar(res.data.error, { variant: 'error' });
      setIsLoading(false);
      return;
    }
    setPost(res.data.data);
    setPostData(res.data.data);
    props.onPostUpdateOrCreate && props.onPostUpdateOrCreate(res.data.data);

    if (!post.publishedAt) {
      enqueueSnackbar('Post saved successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Post saved successfully. \nWe\'ll notify you once it\'s published.', { variant: 'success' });
    } 
    setIsLoading(false);
 
  };

  const handleBlogPublish = async () => {
    if (!post?.id) return;

    setIsLoading(true);
    if (!validateForm('publish')) {
      setIsLoading(false);
      return;
    }
    const res = await axiosInstance.post('/api/configs/post-for-admin', {
      id: post.id,
      siteId: props.siteId,
      publish: true
    });

    if (res.data.error) {
      enqueueSnackbar(res.data.error, { variant: 'error' });
      setIsLoading(false);
      return;
    }
    setPost(res.data.data);
    props.onPostUpdateOrCreate && props.onPostUpdateOrCreate(res.data.data);
    enqueueSnackbar("Post saved successfully. \nWe'll notify you once it's published.", { variant: 'success' });
    setIsLoading(false);
  };

  /**
   * get images embedded in the content text
   * we need these to upload to caddy server
   */
  function getImageUrlsFromContent(htmlContent) {
    // Create a hidden DOM element
    const div = document.createElement('div');
    div.innerHTML = htmlContent;
    
    // Query for all img elements
    const imgElements = div.querySelectorAll('img');

    // Extract the src attribute from each img element
    const imageUrls = Array.from(imgElements).map(img => img.src)
      .filter(src => src.startsWith('https://pagebuilder-images-bucket.s3.amazonaws.com'));
    
    return imageUrls;
  }

  return (
    <div>
      {props.onBackClick && <div className='flex justify-between items-center'>
        <Button
          className="py-1 lg:my-6 my-2 rounded-md"
          sx={{
            textTransform: "none",
          }}
          variant="contained"
          onClick={e => props.onBackClick()}
          size="small"
        >
          <ArrowBackOutlinedIcon
            sx={{
              height: "22px",
              width: "22px",
              marginRight: 0.5
            }}
          ></ArrowBackOutlinedIcon>
          Back
        </Button>
      </div>}
      
      <div className='flex lg:flex-row flex-col'>
        <div className='image-upload-box'>
        <ImagePicker
          size='full'
          image_url={post?.bannerImage}
          siteId={props.siteId}
          title="Post Cover image"
          notes="Please upload a .PNG or .JPG image."
          onImageChange={img_url => setPostData({ ...postData, bannerImage: img_url })}
          error={!!errors['bannerImage']} 
          altError={!!errors['bannerImageAlt']}
          showAlt
          altValue={postData?.bannerImageAlt}
          onAltChange={value => setPostData({ ...postData, bannerImageAlt: value })}
        />
        </div>
        <div className='w-full'>
          <div className='lg:ml-10 ml-0 '>
            <label className='text-base-2 mb-2  text-grey-primary'>Post Url</label>
            <TextField
              defaultValue={props?.post?.slug}
              className="w-full border rounded border-catelina-blue/60 py-1 text-base-2 mb-3  bg-white"
              onChange={e => setPostData({...postData, slug: e.target.value})}
              size="small"
              InputProps={{
                startAdornment: 'xyz.rocketpages.io/blog/',
                classes: {
                  input: 'text-base-2', // Add a class to the input element
                },
                sx: {
                  fontSize: '16px',
                  '& .MuiOutlinedInput-notchedOutline' : {
                    borderColor: '#24306099',
                    borderWidth: 1
                  }
                }, 
              }}
              error={!!errors['slug']}
              helperText={errors['slug']}
            />
            <label className='text-base-2 mb-2  text-grey-primary'>Post Title</label>
            <TextField
              defaultValue={props?.post?.title}
              className="w-full border rounded border-red py-1 text-base-2 mb-3  bg-white"
              placeholder="Post Title"
              onChange={e => setPostData({...postData, title: e.target.value})}
              size="small"
              error={!!errors['title']}
              helperText={errors['title']}
              InputProps={{ 
                classes: {
                  input: 'text-base', // Add a class to the input element
                },
                sx: { 
                  fontSize: '16px',
                  '& .MuiOutlinedInput-notchedOutline' : {
                    borderColor: '#24306099',
                    borderWidth: 1
                  }
                }
              }}
            />
            
            <label className='text-base-2 mb-2  text-grey-primary'>Post Meta Description</label>
            <TextField
              value={postData?.metaDesc}
              className="w-full border rounded border-red py-1 text-base-2 mb-3  bg-white"
              placeholder="Post Meta Description"
              onChange={e => setPostData({...postData, metaDesc: e.target.value})}
              size="small"
              error={!!errors['metaDesc']}
              helperText={errors['metaDesc']}
              InputProps={{ 
                classes: {
                  input: 'text-base', // Add a class to the input element
                },
                sx: { 
                  fontSize: '16px',
                  '& .MuiOutlinedInput-notchedOutline' : {
                    borderColor: '#24306099',
                    borderWidth: 1
                  }
                }
              }}
            />
            
          </div>
        </div>
      </div>

      <label className='text-lg mb-2  text-grey-primary'>Post Body</label>
      <Editor
        ref={quillRef}
        siteId={props.siteId}
        content={post?.content}
        error={errors['content']} 
      />

      <div className='flex justify-end'>
        {post.publishedAt && <Button
          className="py-1 my-6 mr-4"
          sx={{
            textTransform: "none",
          }}
          variant="contained"
          color="error"
          disableElevation
          onClick={e => props.onPostDelete()}
        >
          Delete
        </Button>}
        <Button
          className="py-1 my-6 rounded-md"
          disabled={isLoading}
          sx={{
            textTransform: "none",
          }}
          variant={!post?.publishedAt ? "outlined" : "contained"}
          onClick={e => handleBlogCreate()}
          disableElevation
        >
          
          {post?.publishedAt ? 'Save & Publish' : 'Save'}
        </Button>
        {!post?.publishedAt && <Button
          disabled={!post?.id || isLoading}
          className="py-1 my-6 ml-4 rounded-md"
          sx={{
            textTransform: "none",
          }}
          variant="contained"
          onClick={e => handleBlogPublish()}
        >
          Publish
        </Button>}
      </div>

    </div>
  );
}
