import * as React from "react";
import axiosInstance from "../../axiosInstance";
import { useSnackbar } from "notistack";

const AddEditColorPallets = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isEditMode] = React.useState(props.currFormData ? true : false);
  const [formData, setFormData] = React.useState(
    props.currFormData || {
      id: "",
      category: "",
      colors: ["", "", "", "", "", ""],
    }
  );
  const [errors, setErrors] = React.useState({
    colors: [false, false, false, false, false, false],
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleColorChange = (index, value) => {
    const newColors = [...formData.colors];
    newColors[index] = value;

    const newErrors = [...errors.colors];
    newErrors[index] = !isValidHexColor(value);

    setFormData({
      ...formData,
      colors: newColors,
    });
    setErrors({
      ...errors,
      colors: newErrors,
    });
  };

  const handleSave = async () => {
    // Handle save logic here
    if (formData.id === "" || formData.id === null || formData.id === undefined) {
      enqueueSnackbar("Id can't be empty", {
        variant: "error",
      });
      return;
    }

    if (formData.category === "" || formData.category === null || formData.category === undefined) {
      enqueueSnackbar("Category can't be empty", {
        variant: "error",
      });
      return;
    }

    let hasEmptyColors = false;
    formData.colors.map((oo, idx) => {
      if (oo === "" || oo === undefined || oo === null) {
        hasEmptyColors=true
      }
    });
    const hasErrors = errors.colors.some((error) => error);
    
     

    if (hasErrors || hasEmptyColors) {
      enqueueSnackbar("Color codes can't be empty or invalid", {
        variant: "error",
      });

      return;
    }



    if (!isEditMode && props.data.find((jj) => jj.id === formData.id)) {
      enqueueSnackbar("Id already exists. please enter unique one", {
        variant: "error",
      });

      return;
    }
    try{
      let res = await axiosInstance.put("/api/configs/template-color-theme", formData);
      if(res.status === 200){
        props.onSave();
      }else{
        enqueueSnackbar("something went wrong...!", {
          variant: "error",
        });
      }   
    }catch(err){
      console.log(err);
    } 
  };

  const handleCancel = () => {
    // Handle cancel logic here

    setFormData({
      id: "",
      category: "",
      colors: ["", "", "", "", "", ""],
    });

    setErrors({
      colors: [false, false, false, false, false, false],
    });
    props.onCancel();
  };
  const isValidHexColor = (hex) => {
    const hexRegex = /^#([0-9A-Fa-f]{3}){1,2}$/;
    return hexRegex.test(hex);
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <div className="mb-4  flex flex-row justify-between">
        <label
          className="block text-gray-700 text-sm font-bold mb-2 w-40"
          htmlFor="id"
        >
          ID
        </label>
        <input
          type="text"
          name="id"
          disabled={isEditMode}
          value={formData.id}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="id"
        />
      </div>
      <div className="mb-4 flex flex-row justify-between">
        <label
          className="block text-gray-700 text-sm font-bold mb-2 w-40"
          htmlFor="category"
        >
          Category
        </label>
        <select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="category"
        >
          <option value="">Select a category</option>
          <option value="Bright">Bright</option>
          <option value="Dark">Dark</option>
          <option value="Pastel">Pastel</option>
        </select>
      </div>
      {formData.colors.map((color, index) => (
        <div className="mb-4 flex flex-row" key={index}>
          <label
            className="block text-gray-700 text-sm font-bold mb-2 w-40"
            htmlFor={`color-${index}`}
          >
            Color {index + 1}
          </label>
          <div className="flex flex-col w-full">
            <input
              type="text"
              value={color}
              onChange={(e) => handleColorChange(index, e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id={`color-${index}`}
            />
            {errors.colors[index] && (
              <span className="text-red-500 text-sm italic">
                Invalid hex color code.
              </span>
            )}
          </div>
        </div>
      ))}
      <div className="flex items-center justify-end">
        <button
          onClick={handleCancel}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddEditColorPallets;
