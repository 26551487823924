import { Button, Dialog, DialogActions, DialogTitle, Divider, Pagination, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import BlogCreate from './BlogCreate';
import BlogDetailsForm from './BlogDetailsForm';

export default function Blog(props) {
  const [posts, setPosts] = useState<any>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [editMode, setEditMode] = useState(false);
  const [currentEditPost, setCurrentEditPost] = useState<any>(props?.blogConfig?.blog ?? {});

  const [tab, setTab] = useState(0);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const posts = await axiosInstance.get('/api/configs/posts-for-admin', {
      params: {
        siteId: props.siteId,
      }
    });
    console.log('posts.data', posts.data);
    setPosts(posts.data?.posts?.sort((a, b) => {
      let dateA: any = new Date(a.createdAt);
      let dateB: any = new Date(b.createdAt);
      return dateB - dateA;
    }) ?? []);
    // setTotalPosts(posts.data?.count ?? 0);
  };

  // click of back button
  const handleBack = () => {
    setEditMode(false);
    setCurrentEditPost(null);
  };

  // if a post is updated from child component,
  // we update the posts array to update paginated list
  const handlePostUpdateOrCreate = (post) => {
    if (post.id) {
      const idx = posts.findIndex(p => p.id === post.id);
      if (idx !== -1) {
        posts[idx] = post;
      } else {
        posts.unshift(post);
      }
    }
    setPosts(posts);

    if (post.publishedAt) {
      props.listenToBlogPublish();
    }
    // setEditMode(false);
    // setCurrentEditPost(null);
  };

  const handleDeletePost = async () => {
    if (!currentEditPost) return;

    const res = await axiosInstance.delete('/api/configs/post-for-admin', {
      params: {
        id: currentEditPost.id,
      }
    });
    console.log('res', res);

    const idx = posts.findIndex(p => p.id === currentEditPost.id);
    if (idx !== -1) {
      posts.splice(idx, 1);
    }
    setPosts(posts);
    setShowDeleteDialog(false);
    setCurrentEditPost(null);
    setEditMode(false);
  };

  return (
    <div>

      {!editMode && <>

        {/* blog related settings */}
        <BlogDetailsForm
          blogConfig={props.blogConfig}
          sitePages={props.sitePages}
          onBlogConfigChange={props.onBlogConfigChange}
        />

        <Divider className='md:mt-10 mt-4' 
        sx={{'&.MuiDivider-root' : {
          borderColor: '#3B3B3B66'
        }}}
        />
        <div className='flex justify-between items-center my-5 bg-white border border-catelina-blue/20 py-2 px-4 rounded-md'>
          <h1 className='text-lg my-0'>Posts</h1>
          <Button
            id="add"
            className="py-1.5 px-4 rounded-md text-base"
            sx={{
              textTransform: "none",
            }}
            variant="contained"
            onClick={e => setEditMode(true)}
          >
            Create New Post
          </Button>
        </div>

        {/* paginated posts */}
        <BlogList
          posts={posts}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setEditMode={setEditMode}
          setCurrentEditPost={setCurrentEditPost}
          setShowDeleteDialog={setShowDeleteDialog}

          tab={tab}
          setTab={setTab}
        />
      </>}

      {editMode && <>
        {/* blog create or update form */}
        <BlogCreate
          posts={posts}
          siteId={props.siteId}
          onBackClick={handleBack}
          post={currentEditPost}
          onPostUpdateOrCreate={handlePostUpdateOrCreate}
          onPostDelete={() => setShowDeleteDialog(true)}
        />
      </>}

      {/* post delete confirmation dialog */}
      <BlogDeleteDialog
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        handleDeletePost={handleDeletePost}
      />

    </div>
  );
}

const BlogList = ({
  posts,
  currentPage,
  setCurrentPage,
  setEditMode,
  setCurrentEditPost,
  setShowDeleteDialog, 
  tab,
  setTab,
}) => {


  return <>
    <Tabs value={tab} onChange={(e, newValue) => {
      setCurrentPage(1);
      setTab(newValue);
    }} className='mb-4 border-b-2'>
      <Tab sx={{'&.MuiTab-root': {textTransform: 'capitalize'}}} label={`Published (${posts.filter(post => post.publishedAt).length})`} {...a11yProps(0)} />
      <Tab sx={{'&.MuiTab-root': {textTransform: 'capitalize'}}} label={`Drafts (${posts.filter(post => !post.publishedAt).length})`} {...a11yProps(1)} />
    </Tabs>
    {posts.filter((_, idx) => (currentPage - 1) * 10 < idx + 1 && idx <= (currentPage) * 10 && (tab === 0 ? _.publishedAt : !_.publishedAt))
      .map(post => {
        const postDate = new Date(post.createdAt);
        const formattedDate = postDate.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit'
        }).replace(/\//g, '-');
         
        return (
          <div key={post.id} className='flex justify-between bg-white mb-4 p-6 rounded-md border border-[#E1E4EF] items-start md:flex-row flex-col relative'>
            <div className='flex mr-0 md:mr-10 md:flex-row flex-col'>
                  <div className='border border-[#24306099] bg-[#FAFAFA] h-12 justify-center flex rounded-[6px]' style={{minWidth: '100px', width: '100px'}}>
                  {post.bannerImage ?  <img src={post.bannerImage} alt={post.title} className=' object-cover rounded-[6px] w-full' /> : 
                   <img src={require("../../images/no-image.svg")} alt={post.title} width="30px" />
                  }
                  </div>
                  <div className='md:ml-4 ml-0 md:mt-0 mt-4'>
                    <p className="text-grey-primary font-bold mb-0">{post.title}</p>
                    <p className='text-grey-primary text-sm mb-0'>{formattedDate}</p>
                  </div>
            </div>
        <div className='flex items-center  justify-end w-full  md:w-auto edit-delete-icon'>
          {/* <IconButton edge="start" aria-label="edit" onClick={() => {
            setCurrentEditPost(post);
            setEditMode(true);
          }}> 
            <EditNoteRoundedIcon />
          </IconButton> */}

          <img className='lg:mr-3 mr-2' width="20" src={require("../../images/edit.svg")} 
            onClick={() => {
            setCurrentEditPost(post);
            setEditMode(true);
          }} />

          {/* <IconButton edge="end" aria-label="delete" onClick={() => {
            setCurrentEditPost(post);
            setShowDeleteDialog(true);
          }}>
            <DeleteRoundedIcon />
          </IconButton> */}

          <img className='lg:ml-4 ml-2' width="20" src={require("../../images/delete.svg")} 
           onClick={() => {
            setCurrentEditPost(post);
            setShowDeleteDialog(true);
          }} />

        </div>
      </div>
        )
      })}
    <div className='flex justify-center'>
      <Pagination
        hidden={posts.filter((_) => (tab === 0 ? _.publishedAt : !_.publishedAt)).length <= 10}
        count={Math.ceil(posts.length / 10)}
        page={currentPage}
        variant="outlined"
        shape="rounded"
        onChange={(e, page) => setCurrentPage(page)}
      />
    </div>
  </>
};

const BlogDeleteDialog = ({ showDeleteDialog, setShowDeleteDialog, handleDeletePost }) => {

  return <Dialog
    open={showDeleteDialog}
    onClose={() => setShowDeleteDialog(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Are you sure you want to delete this blog post?
    </DialogTitle>
    
    <DialogActions sx={{justifyContent: 'center'}}>
      <Button onClick={() => setShowDeleteDialog(false)} variant='outlined' disableElevation>Cancel</Button>
      <Button onClick={() => handleDeletePost()} autoFocus color='error' variant='contained' disableElevation>
        Delete
      </Button>
    </DialogActions>
  </Dialog>;
};

function a11yProps(index: number) {
  return {
    id: `blog-tab-${index}`,
    'aria-controls': `blog-tabpanel-${index}`,
  };
}