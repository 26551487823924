import React from "react";
import { useSearchParams } from "react-router-dom";
import ImageUploader from "../components/ImageUploader";

const ImageUploaderPage = () => {
  const [searchParams] = useSearchParams();
  const getQueryParam = (key) => {
    return searchParams.get(key);
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-slate-400">
      {/* <div className="w-[90vw] h-[90vh]"> */}
      <div className="w-full h-screen">
        <ImageUploader siteId={getQueryParam("siteId")} eid= {getQueryParam("eid")} thumbnailsize= {getQueryParam("thumbnailsize")} />
      </div>
    </div>
  );
};

export default ImageUploaderPage;
