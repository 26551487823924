import { Divider } from '@mui/material';
import React from 'react';

const BlogThumbnails = [
  {layout: 'blog_v1', src: require('../../images/BlogListingThumbnail.png'), default: true},
];

export default function BlogLayout(props) {
  
  return (
    <div>
      <h1 className='text-lg mb-2'>Blog Listing Page</h1>

      {BlogThumbnails.map(thumbnail => <img 
        key={thumbnail.layout}
        src={thumbnail.src} 
        alt='Blog Listing Page' 
        className={`mb-4 cursor-pointer ${thumbnail.layout === props.blogConfig?.blog?.layout || (thumbnail.default && !props.blogConfig?.blog?.layout) ? ' border-solid border-2 border-catelina-blue' : ''}`}
        onClick={() => props.onBlogConfigChange({ ...props.blogConfig, blog: { ...props.blogConfig.blog, layout: thumbnail.layout }})}
      />)}

      <Divider className='mt-10' />

      <h1 className='text-lg mb-2'>Blog Post Page</h1>

      {BlogThumbnails.map(thumbnail => <img 
        key={thumbnail.layout}
        src={thumbnail.src} 
        alt='Blog Listing Page' 
        className={`mb-4 cursor-pointer ${thumbnail.layout === props.blogConfig?.post?.layout || (thumbnail.default && !props.blogConfig?.post?.layout) ? ' border-solid border-2 border-catelina-blue' : ''}`}
        onClick={() => props.onBlogConfigChange({ ...props.blogConfig, post: { ...props.blogConfig.post, layout: thumbnail.layout }})}
      />)}

      

    </div>
  );
}
