import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import "../../index.css";
import axiosInstance from "../../axiosInstance";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
// import Modal from "@mui/material/Modal";
// import AddEditColorPallets from "./AddEditColorPallets";

const PublishableTemplatesList = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState<any>([]);
  const [selectAll, setSelectAll] = React.useState(false);
  async function fetchData() {
    try {
      let response = await axiosInstance.get(
        `/api/configs/template-site-configs`
      );

      console.log("//", response.data.data);
      setData(response.data.data);
    } catch (err) {}
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handlePublish = async (id) => {
    let res = await axiosInstance.post(`/api/configs/publish`, {
      siteId: id,
      fullSite: true,
    });
    if (res.status === 200) {
      enqueueSnackbar("Publishing in progress!", {
        variant: "warning",
      });
      // fetchData();
    } else {
      enqueueSnackbar("something went wrong...!", {
        variant: "error",
      });
    }
  };
  return (
    <div className="p-4 bg-white  w-auto max-w-xxl">
      <div className="text-black flex flex-row justify-between mb-4">
        <span>List of templates</span>
        <Button
          sx={{
            textTransform: "none",
          }}
          disabled={!data.filter((kk) => kk.selected === true).length > 0}
          size="small"
          variant="contained"
          onClick={async () => {
            try {
              const promises: Promise<any>[] = [];
              data
                .filter((kk) => kk.selected === true)
                .forEach((row) => {
                  // Push promises without awaiting them individually
                  promises.push(
                    axiosInstance.post(`/api/configs/publish`, {
                      siteId: row.siteId,
                      fullSite: true,
                    })
                  );
                });

              // Await Promise.all to resolve all promises
              let res = await Promise.all(promises);
              enqueueSnackbar(`Publishing ${res.length} sites in progress!`, {
                variant: "warning",
              });

              setData((old) => {
                let _old = [...old];
                for (let ii = 0; ii < _old.length; ii++) {
                  _old[ii].selected =false
                }
                return _old;
              });
              setSelectAll(false);

              console.log("ressss", res); // You can now access the resolved promises
            } catch (error) {
              console.error("Error during publishing", error);
            }
          }}
        >
          {data.filter((kk) => kk.selected === true).length === 0
            ? "Publish Site"
            : `Publish ${
                data.filter((kk) => kk.selected === true).length
              } sites`}
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow className="p-2 ">
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  size="small"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setData((old) => {
                      let _old = [...old];
                      for (let ii = 0; ii < _old.length; ii++) {
                        _old[ii].selected = event.target.checked ? true : false; // Fix this line to toggle selected
                      }
                      return _old;
                    });
                    setSelectAll(!selectAll);
                  }}
                />
              </TableCell>
              <TableCell>Sr.No.</TableCell>
              <TableCell>Site Id</TableCell>
              <TableCell>Template Name (Site Name)</TableCell>
              <TableCell>Page Count</TableCell>
              <TableCell>Last modified on</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, _idx: any) => (
              <TableRow
                key={row.siteId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Checkbox
                    checked={row?.selected === true ? true : false}
                    size="small"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setData((old) => {
                        let _old = [...old];
                        let ii = _old.findIndex(
                          (kk: any) => kk.siteId === row.siteId
                        ); // Corrected
                        if (ii !== -1) {
                          _old[ii].selected = event.target.checked
                            ? true
                            : false; // Fix this line to toggle selected
                        }
                        return _old;
                      });
                    }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {_idx}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.siteId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.templateName} ({row.siteName})
                </TableCell>
                <TableCell align="right">{row.pageCount}</TableCell>
                <TableCell align="left">
                  {new Date(row.updatedAt).toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      sx={{
                        textTransform: "none",
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        handlePublish(row.siteId);
                      }}
                    >
                      Publish
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PublishableTemplatesList;
