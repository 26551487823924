import * as React from "react";
import axiosInstance from "../../axiosInstance";
import { useSnackbar } from "notistack";

const AddEdittemplateCategory = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isEditMode] = React.useState(props.currCategory ? true : false);
  const [formData, setFormData] = React.useState({
    name: props.currCategory || "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async () => {
    // Handle save logic here
    if (
      formData.name === "" ||
      formData.name === null ||
      formData.name === undefined
    ) {
      enqueueSnackbar("Category name can't be empty", {
        variant: "error",
      });
      return;
    }

    // if (!isEditMode && props.data.find((jj) => jj.name === formData.name)) {
    //   enqueueSnackbar("Duplicate category name.", {
    //     variant: "error",
    //   });

    //   return;
    // }

    // if (isEditMode && props.data.find((jj) => jj.name === formData.name)) {
    //   enqueueSnackbar("Duplicate category name.", {
    //     variant: "error",
    //   });

    //   return;
    // }
// return;
    try {
      await axiosInstance.delete(
        `/api/configs/site-categories?name=${props.currCategory}`
      );
      let res = await axiosInstance.put(
        "/api/configs/site-categories",
        formData
      );
      if (res.status === 200) {
        props.onSave();
      } else {
        enqueueSnackbar("something went wrong...!", {
          variant: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    // Handle cancel logic here

    setFormData({
      name: "",
    });
    props.onCancel();
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <div className="mb-4  flex flex-row justify-between">
        <label
          className="block text-gray-700 text-sm font-bold mb-2 w-40"
          htmlFor="id"
        >
          Category Name
        </label>
        <input
          type="text"
          name="name"
          // disabled={isEditMode}
          value={formData.name}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
        />
      </div>

      <div className="flex items-center justify-end">
        <button
          onClick={handleCancel}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddEdittemplateCategory;
