import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InvoiceItem = (props: any) => {
//   console.log("InvoiceItem", props);
  const invoice_date = new Date(props.data.created * 1000);
  return (
    <div
      className={`bg-white border rounded-lg shadow-md p-3 md:p-4 w-full mr-2 my-2  text-xsm sm:text-base grid grid-cols-1 xl:grid-cols-4 gap-4`}
    >
      <span className="self-center">
        {invoice_date.toLocaleDateString("en-GB")}
      </span>
      <span className="self-center flex flex-col sm:flex-row"><span>Invoice:&nbsp;</span><span>{props.data.id}</span></span>
      <span className="xl:text-right self-center">${props.data.amount_paid / 100}</span>
      {props.data.status !== 'uncollectible' && <span className="flex justify-center">
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          href={props.data.hosted_invoice_url}
        >
          <VisibilityIcon className="mx-2" fontSize="small" />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          href={props.data.invoice_pdf}
        >
          <CloudDownloadIcon className="mx-2" fontSize="small" />
        </IconButton>
      </span>}
      {props.data.status === 'uncollectible' && 
      <span className="text-sm mb-0 flex   text-catelina-blue  rounded bg-[#E1E4EF] px-2 py-0 pl-4 relative">
         <InfoOutlinedIcon  className="mr-1 absolute top-1.5 left-1"  style={{ fontSize: 16 }} /> <span className="flex flex-wrap pl-1">Payment failed. View/download unavailable. </span>
      </span>}
    </div>
  );
};

export default InvoiceItem;