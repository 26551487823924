import * as React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import {
  useLocation,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Navbar } from "../components/Layout/Navbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axiosInstance from "../axiosInstance";
import { useSnackbar } from "notistack";
import CookieConsent from "../components/CookieConsent/CookieConsent";

export const PreviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const stateData = location.state;
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const search = useLocation().search;
  const [previewData, setPreviewData] = useState();
  const [colorPallets, setColorPallets] = useState([]);
  // console.log("asasasasa", location.state.selectedColorPallet);
  const [selectedColorPallet, setSelectedColorPallet] = React.useState();
  const [searchParams] = useSearchParams();
  const getQueryParam = (key) => {
    return searchParams.get(key);
  };
  React.useEffect(() => {
    async function fetchData() {
      try {
        let response = await axiosInstance.get(`/api/preview?siteId=${id}`);
        // Redirect to /choose-template if response.data is blank
        if (!response.data) {
          navigate("/choose-template");
          return;
        }
        setPreviewData(response.data.data);

        let responseAllPallets = await axiosInstance.get(
          `/api/configs/all-template-color-theme`
        );
        let rrr = [];
        response.data.data.templateColorPalettes.forEach((row) => {
          let gg = responseAllPallets.data.data.find((kk) => kk.id === row.id);
          if (gg) {
            rrr.push(gg);
          }
        });
        setColorPallets(rrr);
        let _tmplId = getQueryParam("id");
        // console.log('location.state.selectedColorPallet', location.state.selectedColorPallet)
        if (!location.state?.selectedColorPallet && !_tmplId) {
          setSelectedColorPallet(rrr[0]);
        } else {
          let pp = responseAllPallets.data.data.find((kk) =>
            kk.id === _tmplId ? _tmplId : location.state?.selectedColorPallet?.id
          );
          // console.log('debug4.state.selectedColorPallet', pp)
          setSelectedColorPallet(pp);
          // setTimeout(() => {
          let iframe = document.getElementById("previewFrame");
          if (iframe) {
            let iframeWindow = iframe.contentWindow;
            // let documentElement = iframeWindow.document.documentElement;
            iframeWindow.postMessage(
              {
                msgType: "colorPalleteChange",
                color: pp,
              },
              "*"
            );
          }
          // }, 500);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setIsLoading(true);
    fetchData();

    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    let iframe = document.getElementById("previewFrame");
    if (iframe) {
      let iframeWindow = iframe.contentWindow;
      // let documentElement = iframeWindow.document.documentElement;
      iframeWindow.postMessage(
        {
          msgType: "colorPalleteChange",
          color: selectedColorPallet,
        },
        "*"
      );
    }
    // console.log("sss", selectedColorPallet);
  }, [selectedColorPallet]);

  // To hide sitename of preview on mobile view
  const useMobileView = () => {
    const [isMobileView, setIsMobile] = useState(window.innerWidth <= 601);

    React.useEffect(() => {
      const mediaQuery = window.matchMedia("(max-width: 601px)");
      const handleMediaChange = () => setIsMobile(mediaQuery.matches);

      handleMediaChange(); // Initial check
      mediaQuery.addEventListener("change", handleMediaChange); // Listen for changes

      return () => mediaQuery.removeEventListener("change", handleMediaChange); // Cleanup
    }, []);

    return isMobileView;
  };
  let isMobile = useMobileView();

  return (
    <>
      <Helmet>
        <title>RocketPages - Preview</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
        {process.env.SERVER_DOMAIN !== ".rocketpagesweb.com" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
      {previewData && (
        <div className="font-body text-base font-medium">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Navbar
            title={`Preview${
              !isMobile ? ` : ${previewData.templateName}` : ""
            }`}
            colorPallets={colorPallets}
            selectedColorPallet={selectedColorPallet}
            editUrl={`${process.env.EDITOR_ENDPOINT}?tid=${
              previewData.siteId
            }&theme=${selectedColorPallet ? selectedColorPallet.id : ""}`}
            isPreview={true}
            onColorPaletteChange={(color) => {
              setSelectedColorPallet(color);

              // let iframe = document.getElementById("previewFrame");
              // let iframeWindow = iframe.contentWindow;
              // // let documentElement = iframeWindow.document.documentElement;
              // iframeWindow.postMessage(
              //   {
              //     msgType: "colorPalleteChange",
              //     color,
              //   },
              //   "*"
              // );
            }}
          />
          <div className="bg-[#FAFAFA] absolute top-[64px] w-full h-screen">
            <iframe
              id="previewFrame"
              className="w-full h-[96%]"
              src={previewData.previewUrl}
              // src="https://preview-test-1.rocketpages.io"
              onLoad={(e) => {
                // console.log("onLoad.iframe.", selectedColorPallet);
                let iframe = document.getElementById("previewFrame");
                let iframeWindow = iframe.contentWindow;
                iframeWindow.postMessage(
                  {
                    msgType: "colorPalleteChange",
                    color: selectedColorPallet,
                  },
                  "*"
                );
              }}
            ></iframe>
          </div>
        </div>
      )}
      <CookieConsent />
    </>
  );
};
