import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AdminPage from "./pages/admin";
import { ChooseTemplatePage } from "./pages/choose-template";
import { DashboardPage } from "./pages/dashboard";
import { DNSProviderPage } from "./pages/dns-service-providers";
import { ForgotPasswordPage } from "./pages/forgot-password";
import { Homepage } from "./pages/homepage";
import ImageUploaderPage from "./pages/image-uploader";
import { LoginPage } from "./pages/login";
import { UpgradeSuccessPage } from "./pages/plan-success";
import { PreviewPage } from "./pages/preview";
import { PrivacyPolicyPage } from "./pages/privacy-policy";
import { ResetPasswordPage } from "./pages/reset-password";
import { SignupPage } from "./pages/signup";
import { SiteSettingsPage } from "./pages/site-settings";
import { TermsAndConditionsPage } from "./pages/terms-conditions";
// import { CheckoutPage } from "./pages/checkout";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CookiesProvider } from "react-cookie";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
// import { ThemeContext } from "./utils/userContext";
import { SnackbarProvider } from "notistack";
import "sweetalert2/dist/sweetalert2.css";
import NotFoundPage from "./pages/404";
import AccountSetting from "./pages/account-settings";
import { BlogSettingsPage } from "./pages/blog-settings";
import { UpgradeFailPage } from "./pages/plan-fail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/linkedin",
    element: <LinkedInCallback />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "/site-settings/:id",
    element: <SiteSettingsPage />,
  },
  {
    path: "/admin",
    element: <AdminPage />,
  },
  {
    path: "/manage-blog/:id",
    element: <BlogSettingsPage />,
  },
  {
    path: "/preview/:id",
    element: <PreviewPage />,
  },
  {
    path: "/choose-template",
    element: <ChooseTemplatePage />,
  },
  {
    path: "/image-uploder",
    element: <ImageUploaderPage />,
  },
  {
    path: "/404",
    element: <NotFoundPage />,
  },
  {
    path: "/image-uploder",
    element: <ImageUploaderPage />,
  },
  {
    path: "/account-settings",
    element: <AccountSetting />,
  },
  // { path: "/checkout/:subscriptionId/:clientSecret", element: <CheckoutPage /> },
  { path: "/plan-success/:siteId", element: <UpgradeSuccessPage /> },
  { path: "/plan-fail/:siteId", element: <UpgradeFailPage /> },
  { path: "*", element: <NotFoundPage /> },
  {
    path: "/terms-conditions",
    element: <TermsAndConditionsPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/dns-service-providers",
    element: <DNSProviderPage />,
  }
]);

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: "#243060",
    },
    secondary: {
      main: "#04155c",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "Poppins",
        },
        body: {
          fontSize: "3rem",
          color: "purple",
        },
      },
    },
  },
});
const container = document.getElementById("app");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  // <div>
  //   Pagepro best react team in the world
  //   <h1 className="text-3xl font-bold underline">Hello world!</h1>
  // </div>

  <ThemeProvider theme={theme}>
    <CookiesProvider>
      <GoogleOAuthProvider clientId={process.env.GOOGLE_CLEINT_ID || ""}>
        {/* <ThemeContext> */}
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2000}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <div className="App">
            <RouterProvider router={router} />
          </div>
        </SnackbarProvider>
        {/* </ThemeContext> */}
      </GoogleOAuthProvider>
    </CookiesProvider>
  </ThemeProvider>
);
