import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axiosInstance from "../axiosInstance";
import Blog from "../components/BlogSettings/Blog";
import BlogLayout from "../components/BlogSettings/BlogLayout";
import CookieConsent from "../components/CookieConsent/CookieConsent";
import { Navbar } from "../components/Layout/Navbar";
import CategoryDropdown from "../components/SiteSettings/CategoryDropdown";

function checkIsInFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * Blog section in dashboard
 * All other blog components are nested in it.
 */

export const BlogSettingsPage = (props) => {

  const [isInIframe] = useState(checkIsInFrame());
  const [cookies] = useCookies(["rp_user_data"]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveDisabled, setSaveDisabled] = useState();
  const [checkPublishIntervalIterations, setCheckPublishIntervalIterations] = useState(0);
  const [sitePages, setSitePages] = useState([]);

  const updateQueryParam = (key, value) => {
    const params = new URLSearchParams(searchParams);
    params.set(key, value);
    setSearchParams(params);
  };
  const getQueryParam = (key) => {
    return searchParams.get(key);
  };
  const handleUpdateQueryParam = (section) => {
    updateQueryParam("section", section);
  };

  const { id } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [siteData, setSiteData] = useState<any>({});
  const [formData, setFormData] = useState({
    siteId: id,
    siteName: "",
  });
  const stateData = location.state;
  const [siteCategory, setSiteCategory] = useState([
    "Manage Posts",
    "Layout Settings",
  ]);

  const [blogConfig, setBlogConfig] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState("");
  React.useEffect(() => {
    async function fetchSiteData(siteId) {
      try {
        let response = await axiosInstance.get(
          `/api/configs/site-config?siteId=${siteId}`
        );

        console.log("//", response.data);
        setSiteData(response.data);
        setFormData(response.data);
        setIsLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          navigate(`/404?msgType=SiteNotFound`);
        } else if (err.response.status === 401) {
          navigate(`/404?msgType=SiteNotAuthorizedToAccess`);
        } else {
          console.log("err", err.response.data.error);
        }
      }
    }
    fetchSiteData(id);
    let gg = getQueryParam("section");
    if (!gg) {
      setSelectedCategory("General");
      // handleUpdateQueryParam(selectedCategory);
    } else {
      setSelectedCategory(gg);
    }
    // console.log('asasas', gg)
    // setSelectedCategory(gg ? "General" : "");
  }, []);

  React.useEffect(() => {
    handleUpdateQueryParam(selectedCategory);
    // console.log("s", selectedCategory);
  }, [selectedCategory]);


  const updateBlogConfig = async (updatedBlogConfig = blogConfig, publishData = {}) => {
    const response = await axiosInstance.post(`/api/configs/blog?siteId=${id}`, updatedBlogConfig);
    console.log('response', response);
    setSiteData(response.data?.data);
    setBlogConfig(null);

    sendBlogConfigToParent(response.data?.data?.blogConfig);
    if (!response.data?.data?.blogConfig?.isPublished) {
      enqueueSnackbar("Changes saved successfully", {
        variant: "success",
      });
      return;
    }

    enqueueSnackbar("Blog is being published.\nWe'll notify you once it's ready.", {
      variant: "success",
    });

    const publishResponse = await axiosInstance.post(`/api/configs/publish-blog`, { ...publishData, siteId: id,});
    console.log('publishResponse', publishResponse);

    listenToBlogPublish();
  };

  const listenToBlogPublish = async () => {
    const checkPublishInterval = setInterval(async () => {
      if (checkPublishIntervalIterations > 10) {
        clearInterval(checkPublishInterval);
        enqueueSnackbar('Blog publishing failed', { variant: 'error' });
        setCheckPublishIntervalIterations(0);
        return;
      }
      setCheckPublishIntervalIterations(checkPublishIntervalIterations + 1);
      let response = await axiosInstance.get(`/api/configs/site-config?siteId=${id}`);
      console.log('publish status', response.data);
      if (response.data?.blogConfig?.publishingStatus === 'Completed') {
        clearInterval(checkPublishInterval);
        enqueueSnackbar('Blog published successfully', { variant: 'success' });
        sendBlogConfigToParent(response.data?.blogConfig);
      } else if (response.data?.blogConfig?.publishingStatus === 'Error') {
        clearInterval(checkPublishInterval);
        enqueueSnackbar('Blog publishing failed', { variant: 'error' });
        setCheckPublishIntervalIterations(0);
        sendBlogConfigToParent(response.data?.blogConfig);
      } else if (!response.data?.blogConfig?.publishingStatus) {
        clearInterval(checkPublishInterval);
        enqueueSnackbar('Changes saved', { variant: 'success' });
        setCheckPublishIntervalIterations(0);
        sendBlogConfigToParent(response.data?.blogConfig);
      }
    }, 5000);
  }

  // if is rendered in iframe, then send latest blog data to pagebuilder
  const sendBlogConfigToParent = (blogConfig) => {
    if (isInIframe) {
      window.parent.postMessage({ blogUpdated: true, blogConfig }, "*");
    }
  };

  return (
    <>
      <Helmet>
        <title>RocketPages - Site Settings</title>
        <meta
          name="description"
          content="Build and Launch Your Free Website in Just 15 Minutes"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="font-body text-base font-medium">
        <Backdrop
          sx={{
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {!isInIframe && <Navbar
          {...stateData}
          siteId={id}
          title={`Site settings : ${formData ? formData.siteName : ""}`}
        />}
        <div className={"bg-gray-100 flex-row fixed h-screen w-48 hidden md:flex" + (isInIframe ? '' : ' top-[64px]')}>
          {/* <div className="flex "> */}
          <div className="w-full h-full bg-catelina-blue text-white flex flex-col py-2">
            {siteCategory.map((ii) => {
              return (
                <span
                  className={`px-6 hover:cursor-pointer text-sm py-2 ${
                    selectedCategory === ii
                      ? "bg-[#FAFAFA] text-catelina-blue"
                      : ""
                  }`}
                  key={ii}
                  onClick={() => setSelectedCategory(ii)}
                >
                  {ii}
                </span>
              );
            })}
          </div>
          {/* </div> */}
        </div>
        <div className={"flex w-full px-4 md:pl-[280px] pb-10 bg-[#FAFAFA] flex-col pt-5" + (isInIframe ? '' : ' min-h-screen pt-[64px] my-4')}>
          <div className="flex md:hidden py-2">
            <CategoryDropdown
              items={siteCategory}
              handleChange={setSelectedCategory}
              category={selectedCategory}
            />
          </div>
          {formData && selectedCategory === "Manage Posts" && (
            <Blog
              siteId={id}
              blogConfig={siteData?.blogConfig || {}}
              sitePages={sitePages}
              listenToBlogPublish={listenToBlogPublish}
              onBlogConfigChange={updatedConfig => {
                setBlogConfig(updatedConfig);
                let publishData = {};
                if (updatedConfig?.blogConfig?.blog?.url !== siteData?.blogConfig?.blog?.url) {
                  publishData = { ...publishData, prevPageUrl: siteData?.blogConfig?.blog?.url };
                }
                updateBlogConfig(updatedConfig, publishData);
              }}
            />
          )}
          {formData && selectedCategory === "Layout Settings" && (
            <BlogLayout
              siteId={id}
              blogConfig={siteData?.blogConfig || {}}
              onBlogConfigChange={updatedConfig => setBlogConfig(updatedConfig)}
            />
          )}
          
        </div>

        {selectedCategory === "Layout Settings" && <div className="fixed bottom-0 w-full z-50 flex justify-end px-4 py-2 bg-white-smoke">
          <Button
            id="save"
            disabled={!blogConfig}
            className="min-w-[6rem] px-4 py-1 capitalize"
            sx={{
              textTransform: "none",
            }}
            size="small"
            variant="contained"
            onClick={() => updateBlogConfig()}
          >
            Save
          </Button>
        </div>}
        
      </div>
      <CookieConsent />
    </>
  );
};
