const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
// export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
//   const image = await createImage(imageSrc);
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");

//   const maxSize = Math.max(image.width, image.height);
//   const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

//   // set each dimensions to double largest dimension to allow for a safe area for the
//   // image to rotate in without being clipped by canvas context
//   canvas.width = safeArea;
//   canvas.height = safeArea;

//   // // Fill the canvas background with white
//   // ctx.fillStyle = 'white';
//   // ctx.fillRect(0, 0, canvas.width, canvas.height);

//   // translate canvas context to a central location on image to allow rotating around the center.
//   ctx.translate(safeArea / 2, safeArea / 2);
//   ctx.rotate(getRadianAngle(rotation));
//   ctx.translate(-safeArea / 2, -safeArea / 2);

//   // draw rotated image and store data.
//   ctx.drawImage(
//     image,
//     safeArea / 2 - image.width * 0.5,
//     safeArea / 2 - image.height * 0.5
//   );
//   const data = ctx.getImageData(0, 0, safeArea, safeArea);

//   // set canvas width to final desired crop size - this will clear existing context
//   canvas.width = pixelCrop.width;
//   canvas.height = pixelCrop.height;

//   // paste generated rotate image with correct offsets for x,y crop values.
//   ctx.putImageData(
//     data,
//     Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
//     Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
//   );

//   // As Base64 string
//   // return canvas.toDataURL('image/jpeg');

//   // As a blob
//   return new Promise((resolve) => {
//     canvas.toBlob((file) => {
//       // console.log(file);
//       resolve(file)
//       // resolve(URL.createObjectURL(file));
//     }, "image/jpeg", 0.80);
//   });
// }

export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  horizontal,
  vertical,
  contentType
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const radians = getRadianAngle(rotation);
  let flip = { horizontal, vertical };
  // console.log(
  //   "inputParam",
  //   imageSrc,
  //   pixelCrop,
  //   rotation,
  //   horizontal,
  //   vertical,
  //   contentType
  // );
  // Calculate bounding box of the rotated image
  const rotatedWidth =
    Math.abs(image.width * Math.cos(radians)) +
    Math.abs(image.height * Math.sin(radians));
  const rotatedHeight =
    Math.abs(image.width * Math.sin(radians)) +
    Math.abs(image.height * Math.cos(radians));

  // Set canvas dimensions to rotated image dimensions
  canvas.width = rotatedWidth;
  canvas.height = rotatedHeight;

  // Apply flip transformations
  ctx.translate(rotatedWidth / 2, rotatedHeight / 2);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);

  // Rotate the image around its center
  ctx.rotate(radians);
  ctx.translate(-image.width / 2, -image.height / 2);

  // Draw the transformed image
  ctx.drawImage(image, 0, 0);

  // Create a second canvas to draw the cropped portion
  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  // Set dimensions of cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped area from the rotated and flipped image
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x, // x coordinate of the top-left corner of the cropped area
    pixelCrop.y, // y coordinate of the top-left corner of the cropped area
    pixelCrop.width, // width of the cropped area
    pixelCrop.height, // height of the cropped area
    0,
    0,
    pixelCrop.width, // draw the cropped area to fit the canvas width
    pixelCrop.height // draw the cropped area to fit the canvas height
  );

  // Extract the file type from the original image source
  //  const fileType = imageSrc.substring(imageSrc.lastIndexOf('.') + 1).toLowerCase();
  let _contentType = contentType;
  //  console.log('fileType: ' + fileType);
  if (_contentType === "image/gif") {
    _contentType = "image/jpeg";
  }

  // setFileType(contentType);
  // console.log('contentType: ' + contentType);
  // Convert cropped canvas to a Blob
  return new Promise((resolve) => {
    croppedCanvas.toBlob(
      (file) => {
        resolve(file);
      },
      _contentType,
      0.80
    );
  });
}
