import React from "react";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-[999]">
      <div className="bg-white  w-[95vw] h-[95vh] rounded-lg overflow-hidden shadow-xl flex flex-col">
        <div className="flex-grow overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
